import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from './index.module.scss';
import API from '../../helpers/API';
import Controlbar from '../../components/Controlbar';
import Empty from '../../components/Empty';
import { phoneNumber } from '../../utils/Formatter';
import { getOrderStatus } from '../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState();

  useMountEffect(() => {
    API.get('/orders').then(({ success, data }) => {
      if (success) {
        setOrders(data.orders);
      }
    });
  });

  const moveToDetail = (rid) => {
    navigate(`/orders/${rid}`);
  };

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>총 {commaNumber(orders?.length ?? 0)}건</div>
      </Controlbar>
      {orders?.length === 0 && <Empty />}
      {orders?.map((order, index) => (
        <div key={index} className={cx('row')} onClick={() => moveToDetail(order.rid)}>
          <div className={cx('labels')}>
            <div className={cx('status')}>{getOrderStatus(order.status)}</div>
            <div className={cx('orderNo')}>주문번호 {order.originNo}</div>
          </div>
          <div className={cx('meta')}>
            <div className={cx('date')}>{moment(order.createdAt).format('YYYY-MM-DD')}</div>
            <div className={cx('accent')}>수량 {commaNumber(order.quantity)}개</div>
          </div>
          <div className={cx('customer')}>
            {order.deliveryName} · {order.deliveryCompany} · {phoneNumber(order.deliveryPhone)}
          </div>
          <div className={cx('address')}>
            {order.deliveryAddress} {order.deliveryAddressDetail}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Page;
