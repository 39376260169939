import { handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = null;

export default handleActions(
  {
    'ORDER/LOAD': (state, action) => action.payload.order,
    'ORDER/REMOVE_TANK': (state, action) =>
      produce(state, (draft) => {
        draft.orderTanks = state.orderTanks.filter(
          (item) => item.tank.rid !== action.payload.rid
        );
      }),
    'ORDER/RESET': (state, action) => initialState,
  },
  initialState
);
