import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation } from 'react-router-dom';
import { BsHouse, BsBattery, BsBoxArrowRight, BsBoxArrowLeft, BsGear } from 'react-icons/bs';

import styles from './Navigation.module.scss';

const cx = classNames.bind(styles);

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(localStorage.getItem('@token') === null);
  }, [location]);

  const moveTo = (path) => {
    navigate(path);
  };

  if (hidden) {
    return null;
  }

  const role = localStorage.getItem('@role');

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx(['button', { active: location.pathname === '/' }])} onClick={() => moveTo('/')}>
          <BsHouse size={24} />
          <div className={cx('name')}>홈</div>
        </div>
        {['SYSTEM', 'ADMIN', 'MANAGER', 'CHARGER', 'SALES'].includes(role) && (
          <div
            className={cx(['button', { active: location.pathname.startsWith('/tanks') }])}
            onClick={() => moveTo('/tanks')}>
            <BsBattery size={24} />
            <div className={cx('name')}>용기관리</div>
          </div>
        )}
        {['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER'].includes(role) && (
          <div
            className={cx(['button', { active: location.pathname.startsWith('/orders') }])}
            onClick={() => moveTo('/orders')}>
            <BsBoxArrowRight size={24} />
            <div className={cx('name')}>주문배송</div>
          </div>
        )}
        {['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER'].includes(role) && (
          <div
            className={cx(['button', { active: location.pathname.startsWith('/reclaims') }])}
            onClick={() => moveTo('/reclaims')}>
            <BsBoxArrowLeft size={24} />
            <div className={cx('name')}>회수배송</div>
          </div>
        )}
        <div
          className={cx(['button', { active: location.pathname.startsWith('/settings') }])}
          onClick={() => moveTo('/settings')}>
          <BsGear size={24} />
          <div className={cx('name')}>설정</div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
