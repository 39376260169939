import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import styles from './index.module.scss';
import API from '../../helpers/API';
import Empty from '../../components/Empty';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState();

  useMountEffect(() => {
    API.get('/posts').then(({ success, data }) => {
      if (success) {
        setPosts(data.posts.filter((_, index) => index < 10));
      }
    });
  });

  const moveTo = (path) => {
    navigate(path);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('sectionHeader')}>
        <div className={cx('title')}>최근 공지사항</div>
        <div className={cx('more')} onClick={() => moveTo('/posts')}>
          전체보기
        </div>
      </div>
      {posts?.length === 0 && <Empty />}
      {posts?.map((post, index) => (
        <div key={index} className={cx('row')} onClick={() => moveTo(`/posts/${post.rid}`)}>
          <div className={cx('title')}>{post.title}</div>
          <div className={cx('date')}>{moment(post.createdBy).format('YYYY-MM-DD')}</div>
        </div>
      ))}
    </div>
  );
};

export default Page;
