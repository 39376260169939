import React from 'react';
import classNames from 'classnames/bind';

import styles from './WideButton.module.scss';

const cx = classNames.bind(styles);

const WideButton = ({ title, onClick, className }) => {
  return (
    <div className={cx(['container', className])} onClick={onClick}>
      {title}
    </div>
  );
};

export default WideButton;
