import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import styles from './index.module.scss';
import WideButton from '../../../components/WideButton';
import API from '../../../helpers/API';
import { getTankUseStatus, getTankChargeStatus } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tank, setTank] = useState();
  const [message, setMessage] = useState('');

  useMountEffect(() => {
    // API: 용기 상세 조회
    API.get(`tanks/${searchParams.get('qrcode')}`).then(({ success, data }) => {
      if (success) {
        setTank(data);
      } else {
        if (data.code === -9000) {
          setMessage('등록되지 않은 용기입니다.');
        }

        navigate('/tanks', { replace: true });
      }
    });
  });

  // 용기 검사 등록
  const doInspect = () => {
    if (tank.useStatus !== 'READY') {
      setMessage(`준비 상태가 아닌 경우 검사 용기로 등록할 수 없습니다.`);
      return;
    }

    API.patch(`/tanks/${searchParams.get('qrcode')}/inspect`).then(({ success }) => {
      if (success) {
        navigate(
          {
            pathname: '/scanner',
            search: createSearchParams({ callbackUri: '/tanks/inspect' }).toString(),
          },
          { replace: true },
        );
      }
    });
  };

  // 용기 검사 완료 : 준비 상태로 변경
  const completeInspect = () => {
    if (tank.useStatus !== 'INSPECTING') {
      setMessage('검사 중인 용기가 아닙니다.');
      return;
    }

    API.patch(`tanks/${searchParams.get('qrcode')}/inspect/complete`).then(({ success }) => {
      if (success) {
        navigate(
          {
            pathname: '/scanner',
            search: createSearchParams({ callbackUri: '/tanks/inspect' }).toString(),
          },
          { replace: true },
        );
      }
    });
  };

  const isAvailable = useMemo(() => message === '', [message]);

  if (tank === undefined) return;

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        {tank.useStatus !== 'INSPECTING' ? '검사 용기로 등록하시겠습니까?' : '검사 완료 하시겠습니까?'}
      </div>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>QR코드</div>
          <div className={cx('value')}>{tank.qrcode}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>가용상태</div>
          <div className={cx('value')}>{getTankUseStatus(tank.useStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전상태</div>
          <div className={cx('value')}>{getTankChargeStatus(tank.chargeStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>용기 일련번호</div>
          <div className={cx('value')}>{tank.serialNo ?? '-'}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>밸브 일련번호</div>
          <div className={cx('value')}>{tank.velveSerialNo ?? '-'}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전횟수</div>
          <div className={cx('value')}>{tank.chargeCount}회</div>
        </div>
      </div>
      {message && <div className={cx('message')}>{message}</div>}
      {isAvailable ? (
        <WideButton
          title="등록"
          onClick={tank.useStatus !== 'INSPECTING' ? doInspect : completeInspect}
          className={cx('button')}
        />
      ) : (
        <WideButton title="뒤로" onClick={() => navigate(-1)} className={cx('button')} />
      )}
    </div>
  );
};

export default Page;
