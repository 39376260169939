import React from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import commaNumber from 'comma-number';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import { phoneNumber } from '../../../utils/Formatter';
import Progress from './Progress';
import TankSection from './TankSection';
import DeliverySection from './DeliverySection';
import { reclaimActions } from '../../../actions';

const cx = classNames.bind(styles);

const Page = () => {
  const reclaim = useSelector((state) => state.reclaim);
  const dispatch = useDispatch();
  const { rid } = useParams();

  useMountEffect(() => {
    // API: 회수 상세 조회
    API.get(`/reclaims/${rid}`).then(({ success, data }) => {
      if (success) {
        dispatch(reclaimActions.load(data));
      }
    });

    return () => dispatch(reclaimActions.reset());
  });

  if (reclaim === null) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('infoWrapper')}>
        <Progress />
        <div className={cx('rows')}>
          <div className={cx('row')}>
            <div className={cx('label')}>요청일자</div>
            <div className={cx('value')}>{moment(reclaim.createdAt).format('YYYY년 M월 D일')}</div>
          </div>
        </div>
        <div className={cx('infoBox')}>
          <div className={cx('item')}>
            <div className={cx('label')}>회수지 주소</div>
            <div className={cx('value')}>
              {reclaim.customerAddress} {reclaim.customerAddressDetail}
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>요청자</div>
            <div className={cx('value')}>
              {reclaim.customerName} <small>({phoneNumber(reclaim.customerPhone)})</small>
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>수량</div>
            <div className={cx('value')}>{commaNumber(reclaim.quantity)}개</div>
          </div>
        </div>
      </div>
      <TankSection />
      <DeliverySection />
    </div>
  );
};

export default Page;
