import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';

const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useMountEffect(() => {
    const token = localStorage.getItem('@token');
    const role = localStorage.getItem('@role');

    if (token !== null && role !== null) {
      navigate('/');
    }
  });

  return children;
};

export default UnprotectedRoute;
