import { handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = null;

export default handleActions(
  {
    'RECLAIM/LOAD': (state, action) => action.payload.reclaim,
    'RECLAIM/REMOVE_TANK': (state, action) =>
      produce(state, (draft) => {
        draft.reclaimTanks = state.reclaimTanks.filter(
          (item) => item.tank.rid !== action.payload.rid
        );
      }),
    'RECLAIM/RESET': (state, action) => initialState,
  },
  initialState
);
