import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import {
  BsPencilSquare,
  BsHourglass,
  BsTruck,
  BsCheckCircle,
} from 'react-icons/bs';

import styles from './Progress.module.scss';

const cx = classNames.bind(styles);

const Progress = () => {
  const reclaim = useSelector((state) => state.reclaim);

  return (
    <div className={cx('container')}>
      <div
        className={cx([
          'step',
          {
            done: ['SHIPPING', 'RECLAIMED'].includes(reclaim.status),
            active: reclaim.status === 'RECEIVED',
          },
        ])}
      >
        <BsPencilSquare size={20} />
        <div className={cx('title')}>회수접수</div>
      </div>
      <div className={cx('stepLine')} />
      <div
        className={cx([
          'step',
          {
            done: ['RECLAIMED'].includes(reclaim.status),
            active: reclaim.status === 'SHIPPING',
          },
        ])}
      >
        <BsTruck size={20} />
        <div className={cx('title')}>배송중</div>
      </div>
      <div className={cx('stepLine')} />
      <div
        className={cx([
          'step',
          {
            active: reclaim.status === 'RECLAIMED',
          },
        ])}
      >
        <BsCheckCircle size={20} />
        <div className={cx('title')}>회수완료</div>
      </div>
    </div>
  );
};

export default Progress;
