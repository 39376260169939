import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import WideButton from '../../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [searchParams] = useSearchParams();
  const [reclaim, setReclaim] = useState();

  useMountEffect(() => {
    // API: 회수 상세 조회
    API.get(`/reclaims/${rid}`).then(({ success, data }) => {
      if (success) {
        setReclaim(data);
      }
    });
  });

  const saveOutgoing = () => {
    // API: 회수배송 출고
    API.post(`/reclaims/${rid}/outgoing`, {
      destinationRid: searchParams.get('locationRid'),
      qrcodes: reclaim.reclaimTanks.map((item) => item.tank.qrcode),
    }).then(({ success }) => {
      if (success) {
        navigate(`/reclaims/${rid}/`, { replace: true });
      }
    });
  };

  if (reclaim === undefined) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>출고를 처리하시겠습니까?</div>
      <WideButton title="출고" onClick={saveOutgoing} className={cx('button')} />
    </div>
  );
};

export default Page;
