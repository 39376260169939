export const getOrderStatus = (code) => {
  switch (code) {
    case 'RECEIVED':
      return '주문접수';
    case 'CHARGING':
      return '준비중';
    case 'SHIPPING':
      return '배송중';
    case 'DELIVERED':
      return '배송완료';
    case 'CANCELED':
      return '주문취소';
    default:
      return code;
  }
};

export const getReclaimStatus = (code) => {
  switch (code) {
    case 'RECEIVED':
      return '회수접수';
    case 'SHIPPING':
      return '회수중';
    case 'RECLAIMED':
      return '회수완료';
    default:
      return code;
  }
};

export const getTankUseStatus = (code) => {
  switch (code) {
    case 'DRAFT':
      return '임시';
    case 'READY':
      return '준비';
    case 'INUSE':
      return '사용';
    case 'RETIRED':
      return '폐기';
    case 'INSPECTING':
      return '검사';
    default:
      return code;
  }
};

export const getTankChargeStatus = (code) => {
  switch (code) {
    case 'CHARGED':
      return '충전';
    case 'UNCHARGED':
      return '미충전';
    default:
      return code;
  }
};

export const getShippingType = (code) => {
  switch (code) {
    case 'OUTGOING':
      return '출고';
    case 'INCOMING':
      return '입고';
    case 'DELIVERED':
      return '완료';
    default:
      return code;
  }
};

export const getUserRole = (code) => {
  switch (code) {
    case 'SYSTEM':
      return '시스템관리자';
    case 'ADMIN':
      return '통합관리자';
    case 'MANAGER':
      return '충전관리자';
    case 'CHARGER':
      return '충전원';
    case 'DELIVERER':
      return '배송원';
    case 'SALES':
      return '영업사원';
    default:
      return code;
  }
};
