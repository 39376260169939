// 휴대폰 번호
export const phoneNumber = (phone) => {
  try {
    return phone.replace(
      /^(01\d|02|031|032|033|041|042|043|044|051|052|053|054|055|061|062|063|064)(\d{3,4})(\d{4})$/,
      '$1-$2-$3'
    );
  } catch (e) {
    return phone;
  }
};
