import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { BsKey, BsDoorOpen, BsPeople } from 'react-icons/bs';

import styles from './index.module.scss';
import API, { removeAuthToken } from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const currentUserRole = localStorage.getItem('@role');

  // 비밀번호 변경
  const moveToChangePassword = () => {
    navigate('/settings/password');
  };

  // 로그아웃
  const doLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      API.post('/logout');

      removeAuthToken();
      navigate('/login');
    }
  };

  // 사용자 관리
  const moveToUsers = () => {
    navigate('/settings/users');
  };

  return (
    <div className={cx('container')}>
      {['SYSTEM', 'ADMIN', 'MANAGER'].includes(currentUserRole) && (
        <div className={cx('row')} onClick={moveToUsers}>
          <BsPeople size={20} />
          <div className={cx('title')}>사용자 관리</div>
        </div>
      )}
      <div className={cx('row')} onClick={moveToChangePassword}>
        <BsKey size={20} />
        <div className={cx('title')}>비밀번호 변경</div>
      </div>
      <div className={cx('row')} onClick={doLogout}>
        <BsDoorOpen size={20} />
        <div className={cx('title')}>로그아웃</div>
      </div>
    </div>
  );
};

export default Page;
