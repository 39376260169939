import { combineReducers } from 'redux';

import order from './order';
import reclaim from './reclaim';

const rootReducer = combineReducers({
  order,
  reclaim,
});

export default rootReducer;
