import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Navigation from './components/Navigation';
import ProtectedRoute from './helpers/ProtectedRoute';
import UnprotectedRoute from './helpers/UnprotectedRoute';
import Login from './pages/login';
// 홈
import Home from './pages/home';
// 공지사항
import PostList from './pages/posts';
import PostDetail from './pages/posts/detail';
// 용기관리
import Tanks from './pages/tanks';
import TankEdit from './pages/tanks/edit';
import TankCharge from './pages/tanks/charge';
import TankInspect from './pages/tanks/inspect';
// 주문배송
import OrderList from './pages/orders';
import OrderDetail from './pages/orders/detail';
import OrderTank from './pages/orders/tank';
import OrderScanner from './pages/orders/scanner';
import OrderOutgoing from './pages/orders/outgoing';
import OrderIncoming from './pages/orders/incoming';
import OrderChecklist from './pages/orders/checklist';
// 회수배송
import ReclaimList from './pages/reclaims';
import ReclaimDetail from './pages/reclaims/detail';
import ReclaimTank from './pages/reclaims/tank';
import ReclaimScanner from './pages/reclaims/scanner';
import ReclaimOutgoing from './pages/reclaims/outgoing';
import ReclaimIncoming from './pages/reclaims/incoming';
import ReclaimChecklist from './pages/reclaims/checklist';
// 설정
import Settings from './pages/settings';
import Password from './pages/settings/password';
import UserList from './pages/settings/users';
import UserNew from './pages/settings/users/new';
import UserDetail from './pages/settings/users/detail';
// 기타
import Scanner from './pages/scanner';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Navigation />
      <div style={{ paddingTop: 56, paddingBottom: 64 }}>
        <Routes>
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts"
            element={
              <ProtectedRoute>
                <PostList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/posts/:rid"
            element={
              <ProtectedRoute>
                <PostDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'CHARGER', 'SALES']}>
                <Tanks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks/edit"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'CHARGER', 'SALES']}>
                <TankEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks/charge"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'CHARGER', 'SALES']}>
                <TankCharge />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tanks/inspect"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <TankInspect />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/tank"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderTank />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/scanner"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderScanner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/outgoing"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderOutgoing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/incoming"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderIncoming />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders/:rid/checklist"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <OrderChecklist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/tank"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimTank />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/scanner"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimScanner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/outgoing"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimOutgoing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/incoming"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimIncoming />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reclaims/:rid/checklist"
            element={
              <ProtectedRoute allowedRoles={['SYSTEM', 'ADMIN', 'MANAGER', 'DELIVERER']}>
                <ReclaimChecklist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/password"
            element={
              <ProtectedRoute>
                <Password />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings/users"
            element={
              <ProtectedRoute>
                <UserList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/users/new"
            element={
              <ProtectedRoute>
                <UserNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/users/:rid"
            element={
              <ProtectedRoute>
                <UserDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/scanner"
            element={
              <ProtectedRoute>
                <Scanner />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
