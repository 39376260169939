import { createActions } from 'redux-actions';

export const orderActions = createActions(
  {
    LOAD: (order) => ({ order }),
    REMOVE_TANK: (rid) => ({ rid }),
    RESET: () => ({}),
  },
  { prefix: 'ORDER' }
);

export const reclaimActions = createActions(
  {
    LOAD: (reclaim) => ({ reclaim }),
    REMOVE_TANK: (rid) => ({ rid }),
    RESET: () => ({}),
  },
  { prefix: 'RECLAIM' }
);
