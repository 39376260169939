import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import API, { setAuthToken } from '../../helpers/API';
import WideButton from '../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    username: 'agent',
    password: '123qwer@',
  });
  const [message, setMessage] = useState('');
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleChange = (e) => {
    setMessage('');
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'username':
          passwordRef.current.focus();
          break;
        case 'password':
          doLogin();
          break;
        default:
          break;
      }
    }
  };

  // 유효성 검사
  const validAll = () => {
    if (userInfo.username.trim() === '') {
      setMessage('아이디를 입력하세요.');
      return false;
    }
    if (userInfo.password.trim() === '') {
      setMessage('비밀번호를 입력하세요.');
      return false;
    }
    return true;
  };

  // 로그인
  const doLogin = () => {
    // 유효성 검사
    if (!validAll()) {
      return;
    }
    setMessage('');

    API.post('/login', {
      id: userInfo.username,
      password: userInfo.password,
    }).then(({ success, data }) => {
      if (success && data.accessToken) {
        setAuthToken(data.accessToken);
        localStorage.setItem('@role', data.role);
        navigate('/', { replace: true });
      } else {
        setMessage('아이디 또는 비밀번호를 다시 확인하세요.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo')} />
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="username">아이디</label>
          <input
            id="username"
            ref={usernameRef}
            name="username"
            type="text"
            value={userInfo.username}
            placeholder="아이디"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="username"
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="password">비밀번호</label>
          <input
            id="password"
            ref={passwordRef}
            name="password"
            type="password"
            value={userInfo.password}
            placeholder="비밀번호"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="current-password"
          />
        </div>
      </form>
      {message && <div className={cx('message')}>{message}</div>}
      <WideButton title="로그인" onClick={doLogin} className={cx('button')} />
    </div>
  );
};

export default Page;
