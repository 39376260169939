import React, { useState, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import { BsArrowRight, BsArrowDown } from 'react-icons/bs';
import { useNavigate, createSearchParams } from 'react-router-dom';

import styles from './DeliverySection.module.scss';
import API from '../../../helpers/API';
import Button from '../../../components/Button';
import Controlbar from '../../../components/Controlbar';
import { getShippingType } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const DeliverySection = () => {
  const navigate = useNavigate();
  const reclaim = useSelector((state) => state.reclaim);
  const [shippings, setShippings] = useState();
  const [showForm, setShowForm] = useState(false);
  const [locations, setLocations] = useState();
  const [outgoingLocation, setOutgoingLocation] = useState();
  const [incomingLocation, setIncomingLocation] = useState();
  const [isTurnTo, setTurnTo] = useState({
    outgoing: false,
    incoming: false,
  });

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 회수배송 이력 조회
    API.get(`/reclaims/${reclaim.rid}/shipping`).then(({ success, data }) => {
      setShippings(success ? data : []);
    });
  });

  useUpdateEffect(() => {
    // 할당 용기 수량이 회수 수량과 일치하지 않는 경우
    if (reclaim.reclaimTanks.length !== reclaim.quantity) {
      // 입출고 양식 숨김
      setShowForm(false);
    }
  }, [reclaim]);

  useUpdateEffect(() => {
    if (shippings === undefined) return;

    if (shippings.length === 0) {
      setTurnTo({
        outgoing: true,
        incoming: false,
      });
      return;
    }

    // 최종 배송 이력
    const lastShipping = shippings[shippings.length - 1];
    setTurnTo({
      outgoing: lastShipping.type === 'INCOMING',
      incoming: lastShipping.type === 'OUTGOING',
    });
  }, [shippings]);

  useUpdateEffect(() => {
    if (locations === undefined) return;
    if (reclaim.reclaimTanks.length === 0) return;

    if (isTurnTo.outgoing) {
      // API: 용기 상세 조회
      API.get(`/tanks/${reclaim.reclaimTanks[0].tank.rid}`).then(({ success, data }) => {
        if (success) {
          setOutgoingLocation(data.location);
          setIncomingLocation(locations.find((location) => location.rid !== data.location.rid));
        }
      });
    } else if (isTurnTo.incoming) {
      // 최종 배송 이력
      const lastShipping = shippings[shippings.length - 1];
      setOutgoingLocation(lastShipping.location);
      setIncomingLocation(lastShipping.destination);
    }
  }, [reclaim, isTurnTo, locations]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChangeLocation = (e) => {
    setIncomingLocation(locations.find((location) => location.rid === e.target.value));
  };

  // 출고 처리
  const handleOutgoing = () => {
    navigate({
      pathname: `/reclaims/${reclaim.rid}/scanner`,
      search: createSearchParams({
        type: 'outgoing',
        locationRid: incomingLocation.rid,
        qrcodes: reclaim.reclaimTanks.map((item) => item.tank.qrcode).join(','),
      }).toString(),
    });
  };

  // 입고 처리
  const handleIncoming = () => {
    navigate({
      pathname: `/reclaims/${reclaim.rid}/scanner`,
      search: createSearchParams({
        type: 'incoming',
        locationRid: incomingLocation.rid,
        qrcodes: reclaim.reclaimTanks.map((item) => item.tank.qrcode).join(','),
      }).toString(),
    });
  };

  const moveToChecklist = () => {
    navigate({
      pathname: `/reclaims/${reclaim.rid}/checklist`,
      search: createSearchParams({ destinationRid: incomingLocation.rid }).toString(),
    });
  };

  const canEdit = useMemo(() => {
    // 할당 용기 수량이 회수 수량과 일치하고, 배송중 상태 시 수정 가능
    return reclaim.reclaimTanks.length === reclaim.quantity && ['RECEIVED', 'SHIPPING'].includes(reclaim.status);
  }, [reclaim]);

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('title')}>배송 이력</div>
        {canEdit && (
          <div>
            <Button title="이력 추가" onClick={toggleForm} />
          </div>
        )}
      </Controlbar>
      {showForm && (
        <div className={cx('form')}>
          {isTurnTo.outgoing && (
            <div className={cx('tableControl')}>
              <div className={cx('selectWrapper')}>
                <select name="status" readOnly value={outgoingLocation?.rid} className={cx('select')}>
                  {locations?.map((location) => (
                    <option key={location.rid} disabled={location.rid !== outgoingLocation?.rid} value={location.rid}>
                      {location.name}
                    </option>
                  ))}
                </select>
                <BsArrowDown size={24} className={cx('arrow')} />
                <select
                  name="status"
                  value={incomingLocation?.rid}
                  onChange={handleChangeLocation}
                  className={cx('select')}>
                  {locations?.map((location) => (
                    <option key={location.rid} disabled={location.rid === outgoingLocation?.rid} value={location.rid}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              {outgoingLocation.name === '주문고객' && (
                <div className={cx('button')} onClick={moveToChecklist}>
                  인수
                </div>
              )}
              {outgoingLocation.name !== '주문고객' && (
                <div className={cx('button')} onClick={handleOutgoing}>
                  출고
                </div>
              )}
            </div>
          )}
          {isTurnTo.incoming && (
            <div className={cx('tableControl')}>
              <div className={cx('selectWrapper')}>
                <select name="status" readOnly value={outgoingLocation?.rid} className={cx('select')}>
                  {locations?.map((location, index) => (
                    <option key={index} disabled={location.rid !== outgoingLocation?.rid} value={location.rid}>
                      {location.name}
                    </option>
                  ))}
                </select>
                <BsArrowDown size={24} className={cx('arrow')} />
                <select
                  name="status"
                  value={incomingLocation?.rid}
                  onChange={handleChangeLocation}
                  className={cx('select')}>
                  {locations?.map((location) => (
                    <option key={location.rid} disabled={location.rid !== incomingLocation?.rid} value={location.rid}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className={cx('button')} onClick={handleIncoming}>
                입고
              </div>
            </div>
          )}
        </div>
      )}
      {shippings?.length === 0 && <div className={cx('empty')}>배송 이력이 없습니다.</div>}
      {shippings?.map((item, index) => (
        <div key={index} className={cx('row')}>
          <div className={cx('labels')}>
            <div className={cx('index')}>{index + 1}</div>
            <div className={cx('type')}>{getShippingType(item.type)}</div>
          </div>
          <div className={cx('meta')}>
            <div className={cx('date')}>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div className={cx('accent')}>{item.createdBy.name}</div>
          </div>
          <div className={cx('customer')}>
            {item.location.name}&nbsp;
            {item.destination && (
              <>
                <BsArrowRight size={14} />
                &nbsp;
                {item.destination.name}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeliverySection;
