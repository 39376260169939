import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { BsPencilSquare, BsBatteryHalf, BsWrench } from 'react-icons/bs';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();

  // 임시 용기 등록
  const moveToEdit = () => {
    navigate({
      pathname: '/scanner',
      search: createSearchParams({ callbackUri: '/tanks/edit' }).toString(),
    });
  };

  // 용기 충전
  const moveToCharge = () => {
    navigate({
      pathname: '/scanner',
      search: createSearchParams({ callbackUri: '/tanks/charge' }).toString(),
    });
  };

  const moveToInspect = () => {
    navigate({
      pathname: '/scanner',
      search: createSearchParams({ callbackUri: '/tanks/inspect' }).toString(),
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('row')} onClick={moveToEdit}>
        <BsPencilSquare size={20} />
        <div className={cx('title')}>임시 용기 등록</div>
      </div>
      <div className={cx('row')} onClick={moveToInspect}>
        <BsWrench size={20} />
        <div className={cx('title')}>용기 검사 관리</div>
      </div>
      <div className={cx('row')} onClick={moveToCharge}>
        <BsBatteryHalf size={20} />
        <div className={cx('title')}>용기 충전</div>
      </div>
    </div>
  );
};

export default Page;
