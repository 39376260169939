import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BsSquare, BsCheckSquare } from 'react-icons/bs';

import styles from '../../styles/checklist.module.scss';
import WideButton from '../../components/WideButton';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const signPadRef = useRef();
  const { rid } = useParams();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [terms, setTerms] = useState();

  useMountEffect(() => {
    API.get('/checklist').then(({ success, data }) => {
      if (success) {
        setTerms(data.map((term) => ({ ...term, isFit: false, isUnfit: false })));
      }
    });
  });

  const toggleCheck = (index, key) => {
    setMessage('');
    const targetTerm = terms[index];

    if (key === 'isFit') {
      targetTerm.isFit = true;
      targetTerm.isUnfit = false;
    } else {
      targetTerm.isFit = false;
      targetTerm.isUnfit = true;
    }

    setTerms([...terms]);
  };

  const doConfirm = () => {
    const isCheckedAll = terms?.reduce((acc, term) => acc && (term.isFit || term.isUnfit), true);

    if (!isCheckedAll) {
      setMessage('모든 항목이 확인되지 않았습니다.');
      return;
    }

    if (signPadRef.current.isEmpty()) {
      setMessage('서명이 필요합니다.');
      return;
    }

    const answers = terms.map((term) => ({ termRid: term.rid, agreement: term.isFit }));

    const body = {
      checklistAnswers: answers,
      destinationRid: searchParams.get('destinationRid'),
      inspectionSign: signPadRef.current.toDataURL(),
    };

    // API: 회수배송 고객 인수
    API.post(`/reclaims/${rid}/handover`, body).then(({ success }) => {
      if (success) {
        navigate(`/reclaims/${rid}`, { replace: true });
      }
    });
  };

  if (terms === undefined) return;

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('answers')}>
          <div className={cx('answer')}>적합</div>
          <div className={cx('answer')}>부적합</div>
        </div>
      </div>
      <div className={cx('rows')}>
        {terms.map((term, index) => (
          <div key={index} className={cx('row')}>
            <div className={cx('term')}>{`${index + 1}. ${term.question}`}</div>
            <div className={cx('answers')}>
              <div className={cx('answer')}>
                {term.isFit ? <BsCheckSquare /> : <BsSquare onClick={() => toggleCheck(index, 'isFit')} />}
              </div>
              <div className={cx('answer')}>
                {term.isUnfit ? <BsCheckSquare /> : <BsSquare onClick={() => toggleCheck(index, 'isUnfit')} />}
              </div>
            </div>
          </div>
        ))}
        <div className={cx('tankImage')} />
      </div>
      <div className={cx('agreement')}>상기 모든 내용 확인 및 이상 없음에 동의합니다.</div>
      <div className={cx('canvasWrapper')}>
        <div className={cx('blurText')}>서명</div>
        <SignatureCanvas ref={signPadRef} penColor="black" canvasProps={{ className: cx('canvas') }} />
      </div>
      {message && <div className={cx('message')}>{message}</div>}
      <WideButton title="확인" onClick={doConfirm} className={cx('button')} />
    </div>
  );
};

export default Page;
