import classNames from 'classnames/bind';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './new.module.scss';
import WideButton from '../../../components/WideButton';
import API from '../../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    rid: '',
    role: '',
    id: '',
    password: '',
    rePassword: '',
    name: '',
    email: '',
    phone: '',
    memo: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage('');
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const checkValidation = () => {
    if (userInfo.role.trim() === '') {
      setMessage('역할을 입력하세요.');
      return false;
    }
    if (userInfo.id.trim() === '') {
      setMessage('아이디를 입력하세요.');
      return false;
    }
    if (userInfo.password.trim() === '') {
      setMessage('비밀번호를 입력하세요.');
      return false;
    }
    if (userInfo.rePassword.trim() === '') {
      setMessage('확인 비밀번호를 입력하세요.');
      return false;
    }
    if (userInfo.name.trim() === '') {
      setMessage('성명을 입력하세요.');
      return false;
    }
    if (userInfo.password !== userInfo.rePassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return false;
    }
    return true;
  };

  const doSave = () => {
    if (!checkValidation()) return false;
    setMessage('');

    const data = {
      role: userInfo.role,
      id: userInfo.id,
      password: userInfo.password,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      memo: userInfo.memo,
    };

    API.post('/users', data).then(({ success }) => {
      if (success) {
        navigate(-1);
      } else {
        setMessage('저장에 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="role">
            역할 <span className={cx('required')}>*</span>
          </label>
          <select id="role" name="role" value={userInfo.role} onChange={handleChange} className={cx('select')}>
            <option value="" disabled>
              역할을 선택하세요
            </option>
            <option value="CHARGER">충전원</option>
            <option value="DELIVERER">배송원</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="id">
            아이디<span className={cx('required')}>*</span>
          </label>
          <input
            id="id"
            name="id"
            value={userInfo.id}
            type="text"
            placeholder="아이디"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="password">
            비밀번호<span className={cx('required')}>*</span>
          </label>
          <input
            id="password"
            name="password"
            value={userInfo.password}
            type="password"
            placeholder="비밀번호"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="rePassword">
            비밀번호 확인<span className={cx('required')}>*</span>
          </label>
          <input
            id="rePassword"
            name="rePassword"
            value={userInfo.rePassword}
            type="password"
            placeholder="비밀번호 확인"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="name">
            성명<span className={cx('required')}>*</span>
          </label>
          <input
            id="name"
            name="name"
            value={userInfo.name}
            type="text"
            placeholder="성명"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="email">이메일</label>
          <input
            id="email"
            name="email"
            value={userInfo.email}
            type="text"
            placeholder="이메일"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="phone">전화번호</label>
          <input
            id="phone"
            name="phone"
            value={userInfo.phone}
            type="text"
            placeholder="전화번호"
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="memo">메모</label>
          <textarea
            id="memo"
            name="memo"
            value={userInfo.memo}
            type="text"
            placeholder="메모"
            onChange={handleChange}
            className={cx(['field', 'textarea'])}
          />
        </div>
      </form>
      {message && <div className={cx('message')}>{message}</div>}
      <WideButton title="저장" onClick={doSave} className={cx('button')} />
    </div>
  );
};

export default Page;
