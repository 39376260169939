import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import styles from './detail.module.scss';
import API from '../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const { rid } = useParams();
  const [post, setPost] = useState();

  useMountEffect(() => {
    // API: 공지사항 상세 조회
    API.get(`/posts/${rid}`).then(({ success, data }) => {
      if (success) {
        setPost(data);
      }
    });
  });

  if (post === undefined) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>{post.title}</div>
      <div className={cx('date')}>{moment(post.createdBy).format('YYYY-MM-DD')}</div>
      <div className={cx('body')}>{post.body}</div>
    </div>
  );
};

export default Page;
