import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import styles from './index.module.scss';
import API from '../../helpers/API';
import Empty from '../../components/Empty';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState();

  useMountEffect(() => {
    API.get('/posts').then(({ success, data }) => {
      if (success) {
        setPosts(data.posts);
      }
    });
  });

  const moveToDetail = (rid) => {
    navigate(`/posts/${rid}`);
  };

  return (
    <div className={cx('container')}>
      {posts?.length === 0 && <Empty />}
      {posts?.map((post, index) => (
        <div key={index} className={cx('row')} onClick={() => moveToDetail(post.rid)}>
          <div className={cx('title')}>{post.title}</div>
          <div className={cx('date')}>{moment(post.createdBy).format('YYYY-MM-DD')}</div>
        </div>
      ))}
    </div>
  );
};

export default Page;
