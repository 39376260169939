import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { BsPersonPlusFill } from 'react-icons/bs';

import styles from './index.module.scss';
import Controlbar from '../../../components/Controlbar';
import API from '../../../helpers/API';
import { getUserRole } from '../../../utils/CodeMapper';
import { phoneNumber } from '../../../utils/Formatter';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState();

  useMountEffect(() => {
    API.get('/users').then(({ success, data }) => {
      if (success) {
        setUsers(data);
      }
    });
  });

  const moveToNew = () => {
    navigate('/settings/users/new');
  };

  const moveToDetail = (rid) => {
    navigate(`/settings/users/${rid}`);
  };

  if (users === undefined) return;

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('total')}>총 {commaNumber(users.length ?? 0)}건</div>
      </Controlbar>
      {users.map((user) => (
        <div key={user.rid} className={cx('row')} onClick={() => moveToDetail(user.rid)}>
          <div className={cx('role')}>{getUserRole(user.role)}</div>
          <div className={cx('user')}>
            <div className={cx('name')}>{user.name}</div>
            <div className={cx('phone')}>{user.phone === '' ? '-' : phoneNumber(user.phone)}</div>
          </div>
        </div>
      ))}
      <div className={cx('button')} onClick={moveToNew}>
        <BsPersonPlusFill size={24} color="white" />
      </div>
    </div>
  );
};

export default Page;
