import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import WideButton from '../../../components/WideButton';
import { getTankUseStatus, getTankChargeStatus } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [searchParams] = useSearchParams();
  const [tank, setTank] = useState();
  const [message, setMessage] = useState('');

  useMountEffect(() => {
    // API: 용기 상세 조회
    API.get(`/tanks/${searchParams.get('qrcode')}`).then(({ success, data }) => {
      if (success) {
        setTank(data);
      } else {
        if (data.code === -9000) {
          alert('등록되지 않은 용기입니다.');
        }

        navigate(`/reclaims/${rid}`, { replace: true });
      }
    });
  });

  const doAdd = () => {
    setMessage('');

    // API: 회수-용기 할당
    API.post(`/reclaims/${rid}/tanks/${searchParams.get('qrcode')}`).then(({ success }) => {
      if (success) {
        navigate(`/reclaims/${rid}`, { replace: true });
      }
    });
  };

  if (tank === undefined) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>이 용기를 할당하시겠습니까?</div>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>QR코드</div>
          <div className={cx('value')}>{tank.qrcode}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>가용상태</div>
          <div className={cx('value')}>{getTankUseStatus(tank.useStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전상태</div>
          <div className={cx('value')}>{getTankChargeStatus(tank.chargeStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>용기 일련번호</div>
          <div className={cx('value')}>{tank.serialNo}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>밸브 일련번호</div>
          <div className={cx('value')}>{tank.velveSerialNo}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전횟수</div>
          <div className={cx('value')}>{tank.chargeCount}회</div>
        </div>
      </div>
      {message && <div className={cx('message')}>{message}</div>}
      <WideButton title="할당" onClick={doAdd} className={cx('button')} />
    </div>
  );
};

export default Page;
