import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import API, { removeAuthToken } from '../../../helpers/API';
import WideButton from '../../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    oldPassword: '',
    newPassword: '',
    rePassword: '',
  });
  const [message, setMessage] = useState('');
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const rePasswordRef = useRef();

  const handleChange = (e) => {
    setMessage('');
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'oldPassword':
          newPasswordRef.current.focus();
          break;

        case 'newPassword':
          rePasswordRef.current.focus();
          break;

        case 'rePassword':
          doChange();
          break;

        default:
          break;
      }
    }
  };

  // 유효성 검사
  const validAll = () => {
    if (userInfo.oldPassword.trim() === '') {
      setMessage('현재 비밀번호를 입력하세요.');
      return false;
    }
    if (userInfo.newPassword.trim() === '') {
      setMessage('신규 비밀번호를 입력하세요.');
      return false;
    }
    if (userInfo.rePassword.trim() === '') {
      setMessage('확인 비밀번호를 입력하세요.');
      return false;
    }
    return true;
  };

  // 비밀번호 변경
  const doChange = () => {
    // 유효성 검사
    if (!validAll()) {
      return;
    }
    setMessage('');

    API.patch('/users/password', {
      oldPassword: userInfo.oldPassword,
      newPassword: userInfo.newPassword,
    }).then(({ success }) => {
      if (success) {
        removeAuthToken();
        navigate('/login');
      } else {
        setMessage('현재 비밀번호를 다시 확인하세요.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="oldPassword">현재 비밀번호</label>
          <input
            id="oldPassword"
            ref={oldPasswordRef}
            name="oldPassword"
            type="password"
            value={userInfo.oldPassword}
            placeholder="현재 비밀번호"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="current-password"
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="newPassword">신규 비밀번호</label>
          <input
            id="newPassword"
            ref={newPasswordRef}
            name="newPassword"
            type="password"
            value={userInfo.newPassword}
            placeholder="비밀번호"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="new-password"
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="rePassword">비밀번호 확인</label>
          <input
            id="rePassword"
            ref={rePasswordRef}
            name="rePassword"
            type="password"
            value={userInfo.rePassword}
            placeholder="비밀번호 확인"
            onChange={handleChange}
            onKeyPress={pressEnter}
            className={cx('field')}
            autoComplete="new-password"
          />
        </div>
      </form>
      {message && <div className={cx('message')}>{message}</div>}
      <WideButton title="변경" onClick={doChange} className={cx('button')} />
    </div>
  );
};

export default Page;
