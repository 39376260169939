import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Picker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar/lib/Calendar';
import koKR from 'rc-calendar/lib/locale/ko_KR';
import 'rc-calendar/assets/index.css';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import WideButton from '../../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tank, setTank] = useState({
    qrcode: '',
    useStatus: '',
    chargeStatus: '',
    locationRid: '',
    type: '',
    size: '',
    region: '',
    serialNo: '',
    manufacturer: '',
    manufacturedDate: '',
    expireDate: '',
    velveSerialNo: '',
    velveManufacturer: '',
    velveVersion: '',
    velveExpireDate: '',
    memo: '',
  });
  const [locations, setLocations] = useState();
  const [editable, setEditable] = useState(false);
  const [message, setMessage] = useState('');

  useMountEffect(() => {
    // API: 거점 내역 조회
    API.get('/locations').then(({ success, data }) => {
      if (success) {
        setLocations(data.locations);
      }
    });

    // API: 용기 상세 조회
    API.get(`/tanks/${searchParams.get('qrcode')}`).then(({ success, data }) => {
      if (success) {
        setTank({
          ...data,
          locationRid: data.location?.rid ?? '',
          type: data.type ?? '',
          size: data.size ?? '',
          region: data.region ?? '',
          serialNo: data.serialNo ?? '',
          manufacturer: data.manufacturer ?? '',
          manufacturedDate: data.manufacturedDate ? moment(data.manufacturedDate, 'YYYYMMDD') : '',
          expireDate: data.expireDate ? moment(data.expireDate, 'YYYYMMDD') : '',
          velveSerialNo: data.velveSerialNo ?? '',
          velveManufacturer: data.velveManufacturer ?? '',
          velveVersion: data.velveVersion ?? '',
          velveExpireDate: data.velveExpireDate ? moment(data.velveExpireDate, 'YYYYMMDD') : '',
          memo: data.memo,
        });
        setEditable(data.useStatus === 'DRAFT');
      } else {
        if (data.code === -9000) {
          alert('등록되지 않은 용기입니다.');
        }

        navigate('/tanks', { replace: true });
      }
    });
  });

  // 제조일자 변경 시, 용기 충전기한, 밸브 충전기한 갱신
  useUpdateEffect(() => {
    if (tank.manufacturedDate === '') return;

    setTank({
      ...tank,
      expireDate: tank.manufacturedDate.clone().add(expireYears, 'years'),
      velveExpireDate: tank.manufacturedDate.clone().add(5, 'years'),
    });
  }, [tank.manufacturedDate]);

  const handleChange = (e) => {
    setMessage('');
    setTank({
      ...tank,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (key, value) => {
    setTank({
      ...tank,
      [key]: value,
    });
  };

  const handleChangeUseStatus = (e) => {
    // 사용
    if (e.target.value === 'INUSE') {
      setTank({
        ...tank,
        useStatus: 'INUSE',
        chargeStatus: 'CHARGED',
        locationRid: locations.find((location) => location.name === '주문고객').rid,
        manufacturedDate: moment(),
      });
    }
    // 기타
    else {
      setTank({
        ...tank,
        useStatus: e.target.value,
        chargeStatus: 'UNCHARGED',
        locationRid: '',
        manufacturedDate: moment(),
      });
    }
  };

  const validation = () => {
    if (tank.locationRid === '') {
      setMessage('현재 위치가 선택되지 않았습니다.');
      return false;
    }
    if (tank.type === '') {
      setMessage('유형이 선택되지 않았습니다.');
      return false;
    }
    if (tank.size === '') {
      setMessage('용량이 선택되지 않았습니다.');
      return false;
    }
    if (tank.region === '') {
      setMessage('지역이 선택되지 않았습니다.');
      return false;
    }
    if (tank.serialNo.trim() === '') {
      setMessage('용기 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.manufacturer === '') {
      setMessage('용기 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveSerialNo.trim() === '') {
      setMessage('밸브 일련번호가 입력되지 않았습니다.');
      return false;
    }
    if (tank.velveManufacturer === '') {
      setMessage('밸브 제조사가 선택되지 않았습니다.');
      return false;
    }
    if (tank.velveVersion === '') {
      setMessage('밸브 버전이 선택되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    setMessage('');

    // API: 임시 용기 등록
    API.patch(`/tanks/${searchParams.get('qrcode')}`, {
      useStatus: tank.useStatus,
      chargeStatus: tank.chargeStatus,
      locationRid: tank.locationRid,
      type: tank.type,
      size: tank.size,
      region: tank.region,
      serialNo: tank.serialNo,
      manufacturer: tank.manufacturer,
      manufacturedDate: tank.manufacturedDate ? tank.manufacturedDate.format('YYYYMMDD') : '',
      expireDate: tank.expireDate ? tank.expireDate.format('YYYYMMDD') : '',
      velveSerialNo: tank.velveSerialNo,
      velveManufacturer: tank.velveManufacturer,
      velveVersion: tank.velveVersion,
      velveExpireDate: tank.velveExpireDate.format('YYYYMMDD'),
      memo: tank.memo,
    }).then(({ success }) => {
      if (success) {
        navigate('/tanks', { replace: true });
      }
    });
  };

  const isDraft = useMemo(() => tank.useStatus === 'DRAFT', [tank]);
  const isInUse = useMemo(() => tank.useStatus === 'INUSE', [tank]);
  const expireYears = useMemo(() => (tank.region === 'EU/USA' ? 20 : 15), [tank.region]);
  const calendar = <Calendar locale={koKR} showDateInput={false} />;

  return (
    <div className={cx('container')}>
      <form className={cx('fieldsWrapper')}>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="qrcode">QR코드</label>
          <input id="qrcode" name="qrcode" type="text" value={tank.qrcode} readOnly className={cx('field')} />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="useStatus">가용상태</label>
          <select
            name="useStatus"
            value={tank.useStatus}
            disabled={!editable}
            onChange={handleChangeUseStatus}
            className={cx('select')}>
            <option value="DRAFT" disabled>
              임시
            </option>
            <option value="READY">준비</option>
            <option value="INUSE">사용</option>
            <option value="RETIRED" disabled>
              폐기
            </option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="chargeStatus">
            충전상태<small>사용 상태 시, 임의 변경 불가</small>
          </label>
          <select
            name="chargeStatus"
            value={tank.chargeStatus}
            disabled={!editable || isDraft || isInUse}
            onChange={handleChange}
            className={cx('select')}>
            <option value="CHARGED">충전</option>
            <option value="UNCHARGED">미충전</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="chargeStatus">
            현재 위치<small>사용 상태 시, 임의 변경 불가</small>
          </label>
          <select
            name="locationRid"
            value={tank.locationRid}
            disabled={!editable || isDraft || isInUse}
            onChange={handleChange}
            className={cx('select')}>
            <option value="" disabled>
              선택
            </option>
            {locations?.map((location, index) => (
              <option key={index} value={location.rid} disabled={location.name === '주문고객'}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="type">유형</label>
          <select
            name="type"
            value={tank.type}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value="TYPE1">TYPE1</option>
            <option value="TYPE2">TYPE2</option>
            <option value="TYPE3">TYPE3</option>
            <option value="TYPE4">TYPE4</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="size">용량</label>
          <select
            name="size"
            value={tank.size}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value={7}>7L</option>
            <option value={9}>9L</option>
            <option value={10.8}>10.8L</option>
            <option value={12}>12L</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="region">지역</label>
          <select
            name="region"
            value={tank.region}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value="KOREA">KOREA</option>
            <option value="EU/USA">EU/USA</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="serialNo">용기 일련번호</label>
          <input
            id="serialNo"
            name="serialNo"
            type="text"
            value={tank.serialNo}
            placeholder="용기 일련번호"
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="manufacturer">용기 제조사</label>
          <select
            name="manufacturer"
            value={tank.manufacturer}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value="태광">태광</option>
            <option value="일진">일진</option>
            <option value="시노마">시노마</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="manufacturedDate">제조일자</label>
          <Picker onChange={(value) => handleChangeDate('manufacturedDate', value)} calendar={calendar}>
            {({ value }) => (
              <input
                id="manufacturedDate"
                name="manufacturedDate"
                type="text"
                value={tank.manufacturedDate ? tank.manufacturedDate.format('YYYY-MM-DD') : ''}
                placeholder="제조일자"
                disabled={!editable || isDraft}
                readOnly
                onChange={handleChange}
                className={cx('field')}
              />
            )}
          </Picker>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="expireDate">
            용기 충전기한<small>기본값 : 제조일자로부터 {expireYears}년</small>
          </label>
          <Picker onChange={(value) => handleChangeDate('expireDate', value)} calendar={calendar}>
            {({ value }) => (
              <input
                id="expireDate"
                name="expireDate"
                type="text"
                value={tank.expireDate ? tank.expireDate.format('YYYY-MM-DD') : ''}
                placeholder="용기 충전기한"
                disabled={!editable || isDraft}
                readOnly
                onChange={handleChange}
                className={cx('field')}
              />
            )}
          </Picker>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="velveSerialNo">밸브 일련번호</label>
          <input
            id="velveSerialNo"
            name="velveSerialNo"
            type="text"
            value={tank.velveSerialNo}
            placeholder="밸브 일련번호"
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('field')}
          />
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="velveManufacturer">밸브 제조사</label>
          <select
            name="velveManufacturer"
            value={tank.velveManufacturer}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value="태광">태광</option>
            <option value="영도">영도</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="velveVersion">밸브 버전</label>
          <select
            name="velveVersion"
            value={tank.velveVersion}
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('select')}>
            <option value="">선택</option>
            <option value="V01">V01</option>
            <option value="V02">V02</option>
            <option value="V03">V03</option>
            <option value="V03-1">V03-1</option>
            <option value="V04">V04</option>
            <option value="V05">V05</option>
            <option value="V06">V06</option>
            <option value="V07">V07</option>
            <option value="V08">V08</option>
            <option value="V09">V09</option>
            <option value="V10">V10</option>
          </select>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="velveExpireDate">
            밸브 충전기한<small>기본값 : 제조일자로부터 5년</small>
          </label>
          <Picker onChange={(value) => handleChangeDate('velveExpireDate', value)} calendar={calendar}>
            {({ value }) => (
              <input
                id="velveExpireDate"
                name="velveExpireDate"
                type="text"
                value={tank.velveExpireDate ? tank.velveExpireDate.format('YYYY-MM-DD') : ''}
                placeholder="밸브 충전기한"
                disabled={!editable || isDraft}
                readOnly
                onChange={handleChange}
                className={cx('field')}
              />
            )}
          </Picker>
        </div>
        <div className={cx('fieldWrapper')}>
          <label htmlFor="memo">메모</label>
          <textarea
            id="memo"
            name="memo"
            type="text"
            value={tank.memo}
            placeholder="메모"
            disabled={!editable || isDraft}
            onChange={handleChange}
            className={cx('textarea')}
          />
        </div>
      </form>
      {message && <div className={cx('message')}>{message}</div>}
      {editable && <WideButton title="저장" onClick={doSave} className={cx('button')} />}
    </div>
  );
};

export default Page;
