import React, { useState } from 'react';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, useParams, createSearchParams } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import { BsCircle, BsCheckCircleFill } from 'react-icons/bs';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [searchParams] = useSearchParams();
  const [tanks, setTanks] = useState(
    searchParams
      .get('qrcodes')
      .split(',')
      .map((qrcode) => ({ qrcode, confirm: false })),
  );

  useUpdateEffect(() => {
    // 모든 용기 검수 여부
    const isScannedAll = tanks?.reduce((acc, tank) => acc && tank.confirm, true);
    if (!isScannedAll) {
      return;
    }

    navigate({
      pathname: `/reclaims/${rid}/${searchParams.get('type')}`,
      search: createSearchParams({ locationRid: searchParams.get('locationRid') }).toString(),
    });
  }, [tanks]);

  const handleScan = (result, error) => {
    if (error) return;

    // QR 코드 스캔 후 해당 용기 검색
    const foundTank = tanks.find((tank) => tank.qrcode === result.text);
    if (foundTank) {
      // 용기 검수 확인 처리
      foundTank.confirm = true;
      setTanks([...tanks]);
    }
  };

  return (
    <div className={cx('container')}>
      <QrReader constraints={{ facingMode: 'environment' }} onResult={handleScan} className={cx('viewFinder')} />
      <div className={cx('title')}>용기 검수</div>
      {tanks.map((tank, index) => (
        <div key={index} className={cx('row')}>
          {!tank.confirm && <BsCircle size={16} />}
          {tank.confirm && <BsCheckCircleFill size={16} />}
          <div className={cx('qrcode')}>{tank.qrcode}</div>
        </div>
      ))}
    </div>
  );
};

export default Page;
