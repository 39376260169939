import React from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import commaNumber from 'comma-number';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import { phoneNumber } from '../../../utils/Formatter';
import Progress from './Progress';
import TankSection from './TankSection';
import DeliverySection from './DeliverySection';
import { orderActions } from '../../../actions';

const cx = classNames.bind(styles);

const Page = () => {
  const order = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const { rid } = useParams();

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        dispatch(orderActions.load(data));
      }
    });

    return () => dispatch(orderActions.reset());
  });

  if (order === null) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('infoWrapper')}>
        <Progress />
        <div className={cx('rows')}>
          <div className={cx('row')}>
            <div className={cx('label')}>주문번호</div>
            <div className={cx('value')}>{order.originNo}</div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>주문일자</div>
            <div className={cx('value')}>{moment(order.createdAt).format('YYYY년 M월 D일')}</div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>주문자</div>
            <div className={cx('value')}>
              {order.customerName}
              <br />
              <small>
                {order.customerCompany}
                <br />
                {phoneNumber(order.customerPhone)}
              </small>
            </div>
          </div>
        </div>
        <div className={cx('infoBox')}>
          <div className={cx('item')}>
            <div className={cx('label')}>배송지 주소</div>
            <div className={cx('value')}>
              {order.deliveryAddress} {order.deliveryAddressDetail}
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>수신자</div>
            <div className={cx('value')}>
              {order.deliveryName} <small>({phoneNumber(order.customerPhone)})</small>
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>수량</div>
            <div className={cx('value')}>{commaNumber(order.quantity)}개</div>
          </div>
        </div>
      </div>
      <TankSection />
      <DeliverySection />
    </div>
  );
};

export default Page;
