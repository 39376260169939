import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';

import styles from './index.module.scss';
import WideButton from '../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [qrcode, setQrcode] = useState('');
  const [message, setMessage] = useState('');

  // qr코드 스캔 시
  const handleScan = (result, error) => {
    if (error) return;

    navigate({
      pathname: searchParams.get('callbackUri'),
      search: createSearchParams({ qrcode: result.text }).toString(),
    });
  };

  const validation = () => {
    if (qrcode === '') {
      setMessage('QR 코드가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  // qr코드 직접 입력 시
  const doConfirm = () => {
    if (!validation()) return;

    setMessage('');

    navigate({
      pathname: searchParams.get('callbackUri'),
      search: createSearchParams({ qrcode }).toString(),
    });
  };

  return (
    <div className={cx('container')}>
      <QrReader constraints={{ facingMode: 'environment' }} onResult={handleScan} className={cx('viewFinder')} />
      <div className={cx('bottom')}>
        <div className={cx('fieldsWrapper')}>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="qrcode">QR 코드</label>
            <input
              name="qrcode"
              type="text"
              value={qrcode}
              placeholder="QR 코드"
              onChange={(e) => setQrcode(e.target.value)}
              className={cx('field')}
            />
          </div>
        </div>
        {message && <div className={cx('message')}>{message}</div>}
        <WideButton title="확인" onClick={doConfirm} className={cx('button')} />
      </div>
    </div>
  );
};

export default Page;
