import React from 'react';
import classNames from 'classnames/bind';

import styles from './Empty.module.scss';

const cx = classNames.bind(styles);

const Empty = () => {
  return <div className={cx('container')}>조회 결과가 없습니다.</div>;
};

export default Empty;
