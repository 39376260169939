import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BsDashCircleFill } from 'react-icons/bs';

import styles from './TankSection.module.scss';
import API from '../../../helpers/API';
import Button from '../../../components/Button';
import Controlbar from '../../../components/Controlbar';
import { orderActions } from '../../../actions';

const cx = classNames.bind(styles);

const TankSection = () => {
  const navigate = useNavigate();
  const order = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const moveToAdd = () => {
    navigate({
      pathname: '/scanner',
      search: createSearchParams({ callbackUri: `/orders/${order.rid}/tank` }).toString(),
    });
  };

  const removeTank = (rid) => {
    // API: 주문-용기 삭제
    API.delete(`/orders/${order.rid}/tanks/${rid}`).then(({ success }) => {
      if (success) {
        dispatch(orderActions.removeTank(rid));
      }
    });
  };

  const canEdit = useMemo(() => {
    // 주문접수, 준비중 상태 시 수정 가능
    return ['RECEIVED', 'CHARGING'].includes(order.status);
  }, [order]);

  return (
    <div className={cx('container')}>
      <Controlbar>
        <div className={cx('title')}>용기 내역</div>
        {canEdit && (
          <div>
            <Button title="용기 할당" onClick={moveToAdd} />
          </div>
        )}
      </Controlbar>
      {order.orderTanks.length === 0 && <div className={cx('empty')}>할당된 용기가 없습니다.</div>}
      {order.orderTanks.length > 0 && (
        <div className={cx('header')}>
          <div className={cx(['column', 'info'])}>QR코드</div>
          <div className={cx(['column', 'info'])}>일련번호</div>
          {canEdit && <div className={cx(['column', 'deletion'])}>삭제</div>}
        </div>
      )}
      {order.orderTanks.map((item, index) => (
        <div key={index} className={cx('row')}>
          <div className={cx(['column', 'info'])}>{item.tank.qrcode}</div>
          <div className={cx(['column', 'info'])}>{item.tank.serialNo}</div>
          {canEdit && (
            <div className={cx(['column', 'deletion'])} onClick={() => removeTank(item.tank.rid)}>
              <BsDashCircleFill size={16} color="red" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TankSection;
