import classNames from 'classnames/bind';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './detail.module.scss';
import API from '../../../helpers/API';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [message, setMessage] = useState('');
  const [userInfo, setUserInfo] = useState();

  useMountEffect(() => {
    API.get(`/users/${rid}`).then(({ success, data }) => {
      if (success) {
        setUserInfo(data);
      }
    });
  });

  const handleChange = (e) => {
    setMessage('');
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const checkValidation = () => {
    if (userInfo.role.trim() === '') {
      setMessage('역할을 입력하세요.');
      return false;
    }
    if (userInfo.id.trim() === '') {
      setMessage('아이디를 입력하세요.');
      return false;
    }
    if (userInfo.name.trim() === '') {
      setMessage('성명을 입력하세요.');
      return false;
    }
    return true;
  };

  const doSave = () => {
    if (!checkValidation()) return false;
    setMessage('');

    const data = {
      role: userInfo.role,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
      memo: userInfo.memo,
    };

    API.patch(`/users/${rid}`, data).then(({ success }) => {
      if (success) {
        navigate(-1);
      } else {
        setMessage('저장에 실패했습니다.');
      }
    });
  };

  const doRemove = () => {
    if (window.confirm('해당 유저를 삭제하시겠습니까?')) {
      API.delete(`/users/${rid}`).then(({ success }) => {
        if (success) {
          navigate(-1);
        } else {
          setMessage('삭제에 실패했습니다.');
        }
      });
    }
  };

  return (
    <div className={cx('container')}>
      {userInfo && (
        <form className={cx('fieldsWrapper')}>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="role">
              역할 <span className={cx('required')}>*</span>
            </label>
            <select id="role" name="role" value={userInfo.role} onChange={handleChange} className={cx('select')}>
              <option value="" disabled>
                역할을 선택하세요
              </option>
              <option value="CHARGER">충전원</option>
              <option value="DELIVERER">배송원</option>
            </select>
          </div>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="id">
              아이디<span className={cx('required')}>*</span>
            </label>
            <input
              id="id"
              name="id"
              type="text"
              placeholder="아이디"
              value={userInfo.id}
              onChange={handleChange}
              readOnly
              className={cx('field')}
            />
          </div>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="name">
              성명<span className={cx('required')}>*</span>
            </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="성명"
              value={userInfo.name}
              onChange={handleChange}
              className={cx('field')}
            />
          </div>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="email">이메일</label>
            <input
              id="email"
              name="email"
              type="text"
              placeholder="이메일"
              value={userInfo.email}
              onChange={handleChange}
              className={cx('field')}
            />
          </div>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="phone">전화번호</label>
            <input
              id="phone"
              name="phone"
              type="text"
              placeholder="전화번호"
              value={userInfo.phone}
              onChange={handleChange}
              className={cx('field')}
            />
          </div>
          <div className={cx('fieldWrapper')}>
            <label htmlFor="memo">메모</label>
            <textarea
              id="memo"
              name="memo"
              type="text"
              placeholder="메모"
              value={userInfo.memo}
              onChange={handleChange}
              className={cx(['field', 'textarea'])}
            />
          </div>
        </form>
      )}
      {message && <div className={cx('message')}>{message}</div>}
      {userInfo && (
        <div className={cx('actions')}>
          <div title="삭제" onClick={doRemove} className={cx(['button', 'negative'])}>
            삭제
          </div>
          <div title="저장" onClick={doSave} className={cx(['button', 'primary'])}>
            저장
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
