import React, { useState, useMemo } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import WideButton from '../../../components/WideButton';
import { getTankUseStatus, getTankChargeStatus } from '../../../utils/CodeMapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tank, setTank] = useState();
  const [message, setMessage] = useState('');

  useMountEffect(() => {
    // API: 용기 상세 조회
    API.get(`/tanks/${searchParams.get('qrcode')}`).then(({ success, data }) => {
      if (success) {
        setTank(data);
      } else {
        if (data.code === -9000) {
          setMessage('등록되지 않은 용기입니다.');
        }

        navigate('/tanks', { replace: true });
      }
    });
  });

  const doCharge = () => {
    setMessage('');

    if (tank.useStatus !== 'READY') {
      setMessage(`가용상태가 ${getTankUseStatus(tank.useStatus)}인 경우 충전할 수 없습니다.`);
      return;
    }
    if (tank.chargeStatus === 'CHARGED') {
      setMessage(`충전상태가 이미 충전인 경우 충전할 수 없습니다.`);
      return;
    }

    // API: 용기 충전
    API.patch(`/tanks/${searchParams.get('qrcode')}/charge`).then(({ success }) => {
      if (success) {
        navigate(
          {
            pathname: '/scanner',
            search: createSearchParams({ callbackUri: '/tanks/charge' }).toString(),
          },
          {
            replace: true,
          },
        );
      }
    });
  };

  const isAvailable = useMemo(() => message === '', [message]);

  if (tank === undefined) return;

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>이 용기를 충전하셨습니까?</div>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>QR코드</div>
          <div className={cx('value')}>{tank.qrcode}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>가용상태</div>
          <div className={cx('value')}>{getTankUseStatus(tank.useStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전상태</div>
          <div className={cx('value')}>{getTankChargeStatus(tank.chargeStatus)}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>용기 일련번호</div>
          <div className={cx('value')}>{tank.serialNo ?? '-'}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>밸브 일련번호</div>
          <div className={cx('value')}>{tank.velveSerialNo ?? '-'}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>충전횟수</div>
          <div className={cx('value')}>{tank.chargeCount}회</div>
        </div>
      </div>
      {message && <div className={cx('message')}>{message}</div>}
      {isAvailable ? (
        <WideButton title="충전" onClick={doCharge} className={cx('button')} />
      ) : (
        <WideButton title="뒤로" onClick={() => navigate(-1)} className={cx('button')} />
      )}
    </div>
  );
};

export default Page;
