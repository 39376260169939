import React, { useState } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import moment from 'moment';

import styles from './index.module.scss';
import API from '../../../helpers/API';
import { phoneNumber } from '../../../utils/Formatter';
import WideButton from '../../../components/WideButton';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState();

  useMountEffect(() => {
    // API: 주문 상세 조회
    API.get(`/orders/${rid}`).then(({ success, data }) => {
      if (success) {
        setOrder(data);
      }
    });
  });

  const saveOutgoing = () => {
    // API: 주문배송 출고
    API.post(`/orders/${rid}/outgoing`, {
      destinationRid: searchParams.get('locationRid'),
      qrcodes: order.orderTanks.map((item) => item.tank.qrcode),
    }).then(({ success }) => {
      if (success) {
        navigate(`/orders/${rid}/`, { replace: true });
      }
    });
  };

  if (order === undefined) {
    return;
  }

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>출고를 처리하시겠습니까?</div>
      <div className={cx('rows')}>
        <div className={cx('row')}>
          <div className={cx('label')}>주문번호</div>
          <div className={cx('value')}>{order.originNo}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>주문일자</div>
          <div className={cx('value')}>{moment(order.createdAt).format('YYYY년 M월 D일')}</div>
        </div>
        <div className={cx('row')}>
          <div className={cx('label')}>주문자</div>
          <div className={cx('value')}>
            {order.customerName}
            <br />
            <small>
              {order.customerCompany}
              <br />
              {phoneNumber(order.customerPhone)}
            </small>
          </div>
        </div>
      </div>
      <WideButton title="출고" onClick={saveOutgoing} className={cx('button')} />
    </div>
  );
};

export default Page;
